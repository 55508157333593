<template>
  <div id="app">
    <h1 class="title">Василена Винченцо в:</h1>

    <Vasilena />

  </div>
</template>

<script>
import Vasilena from "./components/Vasilena.vue";

export default {
  name: "App",
  components: {
    Vasilena,
  },
};
</script>

<style>
/* playfair-display-regular - latin_cyrillic */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("~@/assets/fonts/playfair-display-v21-latin_cyrillic-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("~@/assets/fonts/playfair-display-v21-latin_cyrillic-regular.woff")
      format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* playfair-display-700 - latin_cyrillic */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("~@/assets/fonts/playfair-display-v21-latin_cyrillic-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("~@/assets/fonts/playfair-display-v21-latin_cyrillic-700.woff")
      format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* raleway-regular - latin_cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("~@/assets/fonts/raleway-v18-latin_cyrillic-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("~@/assets/fonts/raleway-v18-latin_cyrillic-regular.woff")
      format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* raleway-500italic - latin_cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 500;
  src: local(""),
    url("~@/assets/fonts/raleway-v18-latin_cyrillic-500italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("~@/assets/fonts/raleway-v18-latin_cyrillic-500italic.woff")
      format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* raleway-700italic - latin_cyrillic */
@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 700;
  src: local(""),
    url("~@/assets/fonts/raleway-v18-latin_cyrillic-700italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("~@/assets/fonts/raleway-v18-latin_cyrillic-700italic.woff")
      format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

body {
  margin: 40px 0;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  background-color: #bcaaa4;
  -webkit-font-smoothing: antialiased;
}

.title {
  font-family: "Raleway";
  font-size: 24px;
  font-weight: 700;
  color: #5d4037;
  text-align: center;
  padding-top: 10px;
}

p {
  line-height: 1.5em;
}

h1 + p,
p + p {
  margin-top: 10px;
}

.container {
  padding: 40px 80px;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 60vh;
}

.card-wrap {
  margin: 10px;
  -webkit-transform: perspective(800px);
  transform: perspective(800px);
  cursor: pointer;
}

.card-wrap:hover .card-info {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.card-wrap:hover .card-info p {
  opacity: 1;
}

.card-wrap:hover .card-info,
.card-wrap:hover .card-info p {
  -webkit-transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card-wrap:hover .card-info:after {
  -webkit-transition: 5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 5s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.card-wrap:hover .card-bg {
  -webkit-transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 5s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0.95;
}

.card-wrap:hover .card {
  -webkit-transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 40px 5px, white 0 0 0 1px,
    rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
    inset white 0 0 0 6px;
}

.card {
  position: relative;
  -webkit-box-flex: 0;
  flex: 0 0 240px;
  min-width: 340px;
  height: 320px;
  background-color: #333;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
    inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
  -webkit-transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card-bg {
  opacity: 0.7;
  position: absolute;
  top: -20px;
  left: -20px;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  pointer-events: none;
}

.card-info {
  width: -moz-available;
  width: -webkit-fill-available;
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  -webkit-transform: translateY(40%);
  transform: translateY(40%);
  -webkit-transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.card-info p {
  opacity: 0;
  text-shadow: black 0 2px 3px;
  -webkit-transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.card-info * {
  position: relative;
  z-index: 1;
}

.card-info:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.6))
  );
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background-blend-mode: overlay;
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-info h1 {
  font-family: "Playfair Display";
  font-size: 36px;
  font-weight: 700;
  text-shadow: 0.01em 0 #bcaaa4, 0 0.01em #bcaaa4, -0.01em 0 #bcaaa4,
    0 -0.01em #bcaaa4, -0.01em -0.01em #bcaaa4, -0.01em 0.01em #bcaaa4,
    0.01em -0.01em #bcaaa4, 0.01em 0.01em #bcaaa4;
}

.my-buttons {
  display: flex;
  justify-content: space-between;
}

.my-button-class {
  background-color: #e7e7e7;
  border: none;
  color: black;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
}

.card-top {
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}

.card-top h1 {
  font-family: "Playfair Display";
  font-size: 24px;
  font-weight: 700;
  text-align: right;
  text-shadow: 0.01em 0 black, 0 0.01em black, -0.01em 0 black, 0 -0.01em black,
    -0.01em -0.01em black, -0.01em 0.01em black, 0.01em -0.01em black,
    0.01em 0.01em black;
}

.social-media-buttons {
  display: flex;
  justify-content: space-around;
}

[v-cloak] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
