<template>
  <div>
    <div class="container">
      <Card
        v-for="play in plays"
        v-bind:data-image="'https://vasilenavincenzo.com/' + play.image_cached"
        v-bind:key="play.date"
      >
        <h1 slot="date" v-cloak>
          {{ play.date | moment }} <br />
          {{ play.date | moment_hour }}
        </h1>
        <h1 slot="header" v-cloak>{{ play.title }}</h1>
        <p v-if="play.director === 'Стефан Иванов'" slot="content" v-cloak>
          драм. {{ play.director }}<br />{{ play.theatre }}
        </p>
        <p v-else slot="content" v-cloak>
          реж. {{ play.director }}<br />{{ play.theatre }}
        </p>
        <p slot="buttons" class="my-buttons">
          <a class="my-button-class" v-bind:href="play.link" v-cloak>Билети</a>
          <a class="my-button-class" v-bind:href="play.info_url" v-cloak
            >Инфо</a
          >
          <a class="my-button-class" v-bind:href="play.video" v-cloak>Видео</a>
        </p>
      </Card>
    </div>

    <transition name="fade">
      <div v-if="show" class="social-media-buttons">
        <a href="https://www.facebook.com/vasilena.vincenzo" v-cloak
          ><img
            src="https://vasilenavincenzo.com/static/images/icons8-facebook.svg"
            width="100"
            height="100"
            v-bind:alt="'facebook ' + actress"
        /></a>
        <a href="https://www.instagram.com/vasilenavincenzo" v-cloak
          ><img
            src="https://vasilenavincenzo.com/static/images/icons8-instagram-old.svg"
            width="100"
            height="100"
            v-bind:alt="'instagram ' + actress"
        /></a>
        <a href="https://www.facebook.com/yaltaartrooom" v-cloak
          ><img
            src="https://vasilenavincenzo.com/static/images/yalta-art-room-logo.svg"
            width="95"
            height="95"
            v-bind:alt="'Yalta Art Room '"
        /></a>
      </div>
    </transition>
  </div>
</template>

<script>
import Card from "./Card";
import axios from "axios";
import moment from "moment";

export default {
  name: "Vasilena",
  components: { Card },
  data: function () {
    return {
      plays: "",
      actress: "Василена Винченцо",
      show: false,
    };
  },
  methods: {
    getData() {
      var vm = this;
      axios
        .get("https://vasilenavincenzo.com/plays.json")
        .then(function (response) {
          vm.plays = response.data;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    showButtons() {
      var vm = this;

      setTimeout(function () {
        vm.show = true;
      }, 250); // show the buttons after 1/4 seconds
    },
  },
  created: function () {
    this.getData();
    this.showButtons();
  },
  filters: {
    moment: function (date) {
      return moment(date).locale("bg").format("D MMMM");
    },
    moment_hour: function (date) {
      return moment(date).locale("bg").format("HH:mm");
    },
    moment_number_date: function (date) {
      return moment(date).locale("bg").format("DD.MM");
    },
  },
};
</script>


